<template>
  <div class="px-7 py-8">
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
        ><v-overlay></v-overlay
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-card>
        <v-card-text class="py-0 px-0">
          <v-row>
            <v-col
              cols="7"
              class="px-10 py-10"
              style="border-right: 1px solid #ffba00"
            >
              <v-row no-gutters>
                <v-col cols="12" class="pb-0">
                  <div class="fieldHeading px-1">Email Title</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    placeholder="Email Title"
                    outlined
                    dense
                    class="formFields"
                    v-model="emailTitle"
                    :rules="[rules.required]"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <div class="fieldHeading px-1">Email Description</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    placeholder="Email Description"
                    outlined
                    dense
                    class="formFields"
                    v-model="emailDescription"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <div class="fieldHeading px-1">Email Subject</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    placeholder="Email Subject"
                    outlined
                    dense
                    class="formFields"
                    v-model="emailSubject"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <div class="fieldHeading px-1">Email Body</div>
                </v-col>
                <v-col cols="12">
                  <div style="width: 100%; height: 370px">
                    <quillEditor v-model="emailBody" :options="editorOption" />
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn
                    dark
                    height="45"
                    width="180"
                    color="#2c1963"
                    class="text-capitalize"
                    style="border-radius: 10px"
                    :loading="formLoading"
                    @click="submitManualForm"
                  >
                    <span>Save</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="5"
              class="px-10 py-10"
              style="background-color: #fffcf5"
            >
              <v-row>
                <v-col cols="12">
                  <div class="keywordHeading">Available Keywords</div>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <div class="keywordDescription">
                    There are the available keywords to be used in this email
                    templates
                  </div>
                </v-col>
              </v-row>
              <v-row v-for="(item, i) in keywordsList" :key="i">
                <v-col cols="5">
                  <div class="keywordTitle px-4 py-2">{{ item.title }}</div>
                </v-col>
                <v-col cols="1">
                  <div style="font-size: 19px; color: black" class="py-2">
                    :
                  </div>
                </v-col>
                <v-col cols="5">
                  <div class="keyword py-2 px-4">{{ item.key }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { ROUTER_URL } from "@/constants/urls";
import { mapActions } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_EMAIL_MANUAL_POST,
  API_EMAIL_AUTOMATED_MANUAL_PATCH,
  API_EMAIL_GET_DETAIL,
  API_EMAIL_GET_KEYWORDS,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "ManualTemplateForm",
  components: { quillEditor },
  data() {
    return {
      rules: RULES,
      loading: false,
      type: "",
      email_id: null,
      formLoading: false,
      emailTitle: "",
      emailSubject: "",
      emailDescription: "",
      emailBody: "",
      keywordsList: [],
      editorOption: {
        placeholder: "Email body",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ direction: "rtl" }],
            ["clean"],
          ],
        },
      },
    };
  },
  computed: {
    toast() {
      let message = "";
      if (this.type === "add") {
        message = "Email Template Added";
      } else {
        message = "Email Template Updated";
      }
      return message;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
    }),
    getManualEmailDetail() {
      const successHandler = (res) => {
        this.emailTitle = res.data.email_template_detail.email_title;
        this.emailDescription =
          res.data.email_template_detail.email_description;
        this.emailSubject = res.data.email_template_detail.email_subject;
        this.emailBody = res.data.email_template_detail.email_body;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getKeyWords();
      };
      let formData = {};
      formData["email_template_id"] = this.email_id;
      Axios.request_GET(
        API_EMAIL_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getKeyWords() {
      const successHandler = (res) => {
        console.log(res.data);
        this.keywordsList = res.data.email_template_keyword_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET(
        API_EMAIL_GET_KEYWORDS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitManualForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res, "success");
        this.formLoading = false;
        this.$router.push({
          name: ROUTER_URL.adminPanel.children.emailSettings.name,
        });
        setTimeout(() => {
          this.$root.$refs.adminLayout.routeBackEmailTab("manualEmailTemplate");
        }, 500);
        this.showToast({
          message: this.toast,
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Something Went Wrong",
          color: "e",
        });
        this.formLoading = false;
      };
      let formData = {};
      (formData["email_title"] = this.emailTitle),
        (formData["email_description"] = this.emailDescription);
      formData["email_subject"] = this.emailSubject;
      formData["email_body"] = this.emailBody;
      if (this.type === "add") {
        Axios.request_POST(
          API_EMAIL_MANUAL_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        formData["email_template_id"] = this.email_id;
        Axios.request_PATCH(
          API_EMAIL_AUTOMATED_MANUAL_PATCH,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.email_id = this.$route.query.id;
    this.loading = true;
    if (this.type === "edit") {
      this.getManualEmailDetail();
    } else {
      this.getKeyWords();
    }
  },
};
</script>
<style scoped>
.fieldHeading {
  color: rgba(0, 0, 0, 0.8);
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.keywordHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
  color: #2c1963;
  border-bottom: 4px solid #2c1963;
  width: 60%;
}
.keywordDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
}
.keywordTitle {
  color: #000000;
  border: 2px dashed #2c1963;
  font-size: 17px;
  border-radius: 5px;
}
.keyword {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  /* or 310% */

  color: #2c1963;
}
.subHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;

  color: #757575;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
.quill-editor {
  height: 300px;
  font-family: Lato;
}
.v-btn >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  /* or 29px */
  color: #ffffff;
  letter-spacing: 0px;
  font-size: 17px;
}
</style>
